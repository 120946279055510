import React from "react";
import styles from "./embed.module.scss";

const Embed = ({ embed }) => {
  return (
    <div className={styles.element}>
      <div
        className={styles.inner}
        style={{
          paddingBottom: `${embed.aspectRatio || 56.25}%`,
        }}
        dangerouslySetInnerHTML={{ __html: embed.code }}
      />
    </div>
  );
};

export default Embed;
